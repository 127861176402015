<template>
  <div v-if="isAuthenticated">
    <div class="grid-x grid-padding-x">
      <div class="cell callout primary"><h3><i class="fi-database"></i>&nbsp;&nbsp;&nbsp;Dokumente</h3>
      </div>
    </div>

    <div class="grid-x grid-padding-x grid-padding-y">
      <div class="cell">
        <md-card>
          <md-tabs id="organization-tabs-id" md-dynamic-height @md-changed="onTabChanged">
            <md-tab id="tab-document-forms-id" md-label="Formulare">
            </md-tab>
            <md-tab id="tab-general-documents-id" md-label="Allgemeine Dokumente">
            </md-tab>
          </md-tabs>
        </md-card>
      </div>
    </div>
  </div>
</template>


<script>
  import Spinner from 'vue-simple-spinner';
  import jQuery from "jquery";

  export default {
    name: 'ParentsDocuments',
    components: {
        'vue-simple-spinner': Spinner
    },

    mounted() {
      setTimeout(() => jQuery(document).foundation(), 1000);
    },

    data() {
      return {
        sending: false,
        showEditOrganizationDialog: false,
        selectedOrganization: null,
        success: false,
        successMsg: '',
        error: false,
        errorMsg: '',
        editOrganizationDialogButtonDisabled: false,

        organizationContract: null,
      }
    },

    methods: {

      onTabChanged(tabId) {
        console.log('id ' + tabId);
      },
    },

    computed: {
      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isParent() {
        return this.$store.getters.parent
      },

      isBeta() {
        return this.$store.getters.beta
      },

      labels() {
        return this.$store.getters.labels
      },
    }
  }
</script>

<style lang="scss">
</style>
